import axios from "axios"
import { action, computed, makeObservable, observable } from "mobx"
import store from "store"

import queryString from "query-string"
import RegistrationStore from "store/RegistrationStore"
import * as Sentry from "@sentry/browser"
import config from "store/store.config"
import { VehicleProfile } from "./VehicleProfile"
import _, { sortBy } from "lodash-es"
import { InvoiceModel } from "./model/InvoiceModel"
import { UserProfile } from "./UserProfile"
import shop from "./shop"
import { slugifyLicensePlate } from "../utils/slugifyLicensePlate"
import { CUSTOMER_VIEWABLE_STATUS } from "../component/OfferStatus"
import { OrderHistoryModel } from "./model/OrderHistoryModel"
import { TyreProfileModel } from "./tyreProfileModel";

const IS_ARCHIVED_DATE = "1900-01-01"
// const LOGIN_URL = `${config.URL_BASE}account/clientLogin`
// const REGISTER_URL = `${config.URL_BASE}account/ClientRegister`
const REGISTER_URL = `${config.URL_BASE}api/v1/customers`
// const WHOAMI_URL = `${config.URL_BASE}account/ClientUser`
export const WHOAMI_URL = `${config.URL_BASE}api/v1/customers/me`
export const TOKEN_URL = `${config.URL_BASE}connect/token`
const API_KEY = process.env.REACT_APP_PIMCORE_KEY || "6fad107720155a70d6d052e690af3d88"

const tyreModes = {
  FRONT: "front", REAR: "rear", ALL: "all"
}

export class TyreProfileStore {

  static async get(vehicleId, cacheBuster = "BASE") {
    if (vehicleId.includes("local-")) return []

    try {
      const res = await shop.fetchOnce(`v1/customers/me/vehicles/${vehicleId}/tyres?cache=${cacheBuster}`)
        .then(res => res.json())
        .then(tyres => {
          return tyres.map(tyre => new TyreProfileModel(tyre, tyres))
        })
      return res
    } catch (err) {
      console.error(err)
      return []
    }
  }

  static async post(vehicleId, tyreProfile) {
    const res = await shop.fetch(`v1/customers/me/vehicles/${vehicleId}/tyres`, {
      method: "POST", body: JSON.stringify(tyreProfile)
    })
    return res
  }


  static async delete(vehicleId, tyreProfile) {
    const res = await shop.fetch(`v1/customers/me/vehicles/${vehicleId}/tyres/${tyreProfile.tyreId}`, {
      method: "DELETE", body: JSON.stringify(tyreProfile)
    })
    console.log({ res })
    return res
  }


  static async put(vehicleId, tyreProfile) {
    if (!tyreProfile.tyreId) {
      throw new Error("tyreId is required")
    }
    const res = await shop.fetch(`v1/customers/me/vehicles/${vehicleId}/tyres/${tyreProfile.tyreId}`, {
      method: "PUT", body: JSON.stringify(tyreProfile)
    })
    console.log({ res })
    return res
  }


  getTyreProfileById(id) {
    return this.tyreProfiles.find(tp => tp.id === id)
  }
}


export default class SessionStore {
  loading = false
  token = false
  profile = false
  originalUserProfile = false
  vehicle = false
  ready = false
  busy = true
  isLoggedIn = false
  vehicleByVIN = new Map()
  vehicleById = new Map()
  invoiceByVehicleProfile = new Map()
  identityUserId = null

  invoices = []
  invoiceById = new Map()
  customer = false
  customerOffers = new Set()
  offersByLicensePlateUnfiltered = {}
  offersByLicensePlate = {}
  openAppointments = []
  openAppointmentsByVIN = {}

  orders = []
  ordersByLicensePlate = {}

  storeFilterKey = false

  authorization = null
  statusCache = {}
  loginData = {
    email: "", password: ""
  }

  constructor() {
    makeObservable(this, {
      loading: observable,
      token: observable,
      profile: observable,
      originalUserProfile: observable,
      vehicle: observable,
      busy: observable,
      ready: observable,
      isLoggedIn: observable,
      vehicleByVIN: observable,
      vehicleById: observable,
      invoiceByVehicleProfile: observable,
      identityUserId: observable,
      customer: observable,
      customerOffers: observable,
      offersByLicensePlate: observable,
      offersByLicensePlateUnfiltered: observable,
      invoices: observable,
      invoiceById: observable,
      orders: observable,
      ordersByLicensePlate: observable,
      openAppointments: observable,
      storeFilterKey: observable,
      isLDAP: computed,
      setStoreFilterKey: action,
      setIdentity: action,
      checkEmailInternal: action,
      resetPassword: action,
      resendPassword: action,
      destroySession: action,
      hydrate: action,
      loginData: observable,
      logout: action,
      updateVehicleProfile: action,
      whoami: action,
      fetchInvoices: action,
      fetchInvoicesTeam: action,
      setVehicle: action,
      putDetailsWithoutIdentityId: action,
      putDetails: action,
      putClientSettings: action,
      registerExternal: action,
      ping: action,
      fetchOrderHistory: action,
      registerSilent: action,
      register: action.bound,
      loginWithPassword: action,
      handleError: action,
      setTokenFromResponse: action,
      setLoginData: action
    })

    this.registration = new RegistrationStore({ sessionStore: this })
    this.identityUserId = this.getItem("identityUserId")
    console.log("DEFAULT storeFilterKey", this.storeFilterKey)
    this.customer = JSON.parse(this.getItem("customer") || false)


    // UserProfile needs sessionStore initialized
    setTimeout(() => {
      this.loadOriginalUser()
    }, 0)
  }


  loadOriginalUser() {
    const ogData = this.getItem("ncg:originalUserProfile")
    if (ogData) {
      try {
        this.originalUserProfile = new UserProfile(ogData)
      } catch (err) {
        console.error(err)
        console.error("Can't parse originalUserProfile")
      }
    }
  }

  get isLDAP() {
    if (this.profile) {
      return this.originalUserProfile?.isLDAP || this.profile?.isLDAP
    }
    return false
  }


  setStoreFilterKey(value) {
    this.storeFilterKey = value
  }

  async fetchOrderHistory() {
    let res = await shop.fetch("v1/confirmation/me").then((res) => res.json())
    const ordersSorted = sortBy(res, "createdAt").reverse()
    this.orders = ordersSorted.map((order) => new OrderHistoryModel(order))
    this.ordersByLicensePlate = {}

    this.orders.forEach(order => {
      let licensePlate = slugifyLicensePlate(order.customerVehicle.licensePlate)
      let bucket = this.ordersByLicensePlate[licensePlate] || []
      bucket.push(order)
      this.ordersByLicensePlate[licensePlate] = bucket
    })

    return this.orders
  }


  getLatestOrderForLicensePlate(licensePlateSlug) {
    let bucket = this.ordersByLicensePlate[licensePlateSlug]
    if (bucket) {
      return bucket[0]
    }
    return null
  }

  async setIdentity({ customer }) {
    this.customer = customer
    if (this.originalUserProfile) {
      this.originalUserProfile.vehicles = []
    }
    this.originalUserProfile = new UserProfile(this.originalUserProfile || this.profile)
    localStorage.setItem("ncg:originalUserProfile", JSON.stringify({ ...this.originalUserProfile, vehicles: [] }))
    console.log("SET OG PROFILE", this.originalUserProfile, this.originalUserProfile.email)
    this.identityUserId = customer?.identityUserId
    this.setItem("identityUserId", this.identityUserId)
    // this.storeFilterKey = this.originalUserProfile.primaryStoreKey || this.storeFilterKey
    this.setItem("customer", JSON.stringify(this.customer))
    return await this.whoami(false, { resetProfile: true })
  }

  async refreshCustomerAsEmployee({ customer }) {
    const originalUser = this.customer;

    console.log("CUSTOMER", customer)

    try {
      await this.setIdentity({ customer, noProcessing: true })
      await this.whoami(true, { noProcessing: true })

      await this.setIdentity({ customer: originalUser, noProcessing: true })
      await this.whoami(false, { noProcessing: true })
    } catch (e) {
      return false
    }

    return true
  }

  getIntrospection() {
    const form = new FormData()
    form.append("token", this.access_token)
    return axios(`${config.URL_BASE}connect/introspect`, {
      method: "POST", headers: {
        "Authorization": this.authorization, "Content-Type": "application/x-www-form-urlencoded"
      }, data: form
    })
      .then(res => res.data)
      .catch(err => {
        console.info("CONFIRMATION FAILED", err)
        return false
      })
  }

  confirmStatus(email) {
    const params = queryString.stringify({ email })
    return axios(`${config.URL_BASE}api/v1/customers/confirmation?${params}`, {
      method: "GET", headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.data)
      .then(res => {
        return res.confirmed
      }).catch(err => {
        console.info("CONFIRMATION FAILED", err)
        return false
      })
  }

  confirmEmail({
    userId, code
  }) {
    return axios(`${config.URL_BASE}api/v1/customers/${userId}/confirmation`, {
      method: "POST", data: JSON.stringify({
        customerId: userId, code
      }), headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.status < 400
      })
      .catch(err => {
        return false
      })
  }

  checkCustomerStatus(email) {
    if (this.statusCache[email]) return this.statusCache[email]
    // const params = queryString.stringify( { email } )
    const request = axios(`${config.URL_BASE}api/v1/customers/status`, {
      timeout: 7003, method: "POST", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }, data: {
        email: `${email}`.trim()
      }
    })
      .then(res => {
        let { isLdapUser } = res.data
        // console.log( "LDAP", res.data )
        return res.data
      })
      .catch(err => {
        //ASSUME EVERYTHING IS OK as a Fallback
        return {
          isEmailConfirmed: true, isLdapUser: false, isOnlineCustomer: true, isPasswordResetRequired: false, isStoreCustomer: true
        }
      })

    this.statusCache[email] = request
    return request
  }

  checkEmailInternal(email) {
    const params = queryString.stringify({ email })
    return axios(`${config.URL_BASE}api/v1/customers/internal?${params}`, {
      timeout: 7001, method: "HEAD", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }
    })
      .then(res => {
        // --> console.log( "INTERNAL", {res} )
        return true
      })
      .catch(res => {
        // --> console.log( {email, res} )
        return false
      })
  }

  resetPassword({
    userId, code, password, confirmPassword
  }) {
    return fetch(`${config.URL_BASE}api/v1/customers/password`, {
      method: "POST", body: JSON.stringify({
        identityUserId: userId, code, password, confirmPassword
      }), headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }
    })
      .then(async res => {
        if (res.status > 300) {
          let error = await res.json()
          return { error: error[0] }
        }
        return res.json()
      })
      .then(async data => {
        if (data.error) return data
        console.log({ data })
        const userName = data.userName
        if (userName) {
          await this.loginWithPassword(userName, password, true)
        }
        return data
      }).catch(err => {
        console.error("PW_RESET_ERROR", err)
        return { error: { code: "UNKNOWN" } }
      })
  }

  resendConfirmationEmail(email) {
    const params = queryString.stringify({ email })
    return fetch(`${config.URL_BASE}api/v1/customers/newconfirmation?${params}`)
  }

  async terminateDataProtection() {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Sind Sie sicher, dass sie Ihre Datenschutzerklärung widerrufen wollen?')) {
      return false
    }

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/v1/customers/dse/termination`, {
        headers: {
          'Authorization': this.authorization, 'IdentityUserId': this.profile.identityUserId || this.identityUserId
        }
      })
    } catch (e) {
      console.error('Error when terminating DSE', e)
      return false
    }

    return true
  }


  resendPassword(email) {
    return fetch(`${config.URL_BASE}account/ClientForgotPassword?email=${encodeURIComponent(email)}&redirecturl=${document.location.origin}`, {
      method: "GET", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }
    })
  }

  destroySession() {
    this.token = null
    this.profile = null
    this.identityUserId = null
    this.customer = null
    store.remove("token")
    locationStore.clear()
  }

  async hydrate() {
    let token = store.get("token")
    if (!token) {
      this.ready = true
    } else {
      this.authorization = `Bearer ${token}`
      this.access_token = token
      await this.whoami()
    }

    this.profile && this.fetchOrderHistory()
    cartStore.hydrate()
    await locationStore.get()
    await locationStore.hydrate()
    this.profile && this.fetchOpenAppointments()
  }


  fetchAdditionalData() {
    this.profile && this.fetchOrderHistory()
    this.profile && this.fetchOpenAppointments()
  }

  logout = ({ reload = true } = {}) => {
    locationStore.setActiveManufacturerKey(locationStore.activeManufacturerKey)
    locationStore.setActiveStoreId(locationStore.activeStoreId)
    locationStore.setActiveVehicle(false, false)
    locationStore.setActiveVehicleProfile(false)
    this.isLoggedIn = false
    this.destroySession()

    locationStore.clear()
    reload && document.location.reload()
  }

  updateVehicleProfile(vehicleId, update) {
    let vehicleProfile = this.vehicleById.get(vehicleId)
    if (!vehicleProfile) return null
    let newVehicle = new VehicleProfile(Object.assign(vehicleProfile, update))
    this.vehicleById.set(vehicleId, newVehicle)
    console.log({
      vehicleId, update, newVehicle
    })
  }


  whoami = (forceReloadFromCare = false, {
    resetProfile = false, noProcessing = false
  } = {}) => {
    if (!noProcessing) this.loading = true

    if (forceReloadFromCare) {
      this._profilesLoaded = false
    }

    return axios(`${config.URL_BASE}api/v1/customers/me`, {
      method: forceReloadFromCare ? "PATCH" : "GET", credentials: "include", headers: {
        "Content-Type": "application/json", "Authorization": this.authorization, "IdentityUserId": this.identityUserId
      }, data: forceReloadFromCare ? JSON.stringify({ isConfirmed: true }) : null
    })
      .then(res => res.data)
      .then(async profile => {

        if (!forceReloadFromCare) {
          this.ping()
        }

        if (noProcessing !== true) {
          this.loading = false

          this.profile = this.profile || new UserProfile(profile)

          if (!this.originalUserProfile) {
            this.originalUserProfile = new UserProfile(profile)
          }

          if (resetProfile) {
            this.profile = new UserProfile(profile)
          }
          this.isLoggedIn = true
          profile.vehicles = profile.vehicles.map((data, index) => {
            return new VehicleProfile(data, index)
          })

          if (profile.vehicles.length <= 20) {
            //load invoices
            const promises = profile.vehicles.map(vehicleProfile => this.fetchInvoices(vehicleProfile.vehicleId))
            let invoices
            Promise.all(promises)
              .then((invoiceLists) => {
                invoices = _.flatten(invoiceLists)
                this.invoices = invoices
                this.invoices.forEach(invoice => {
                  this.invoiceById.set(invoice.invoiceId, invoice)
                })
              })

          }


          if (sessionStore.profile.primaryStoreKey) {
            locationStore.setActiveStoreId(sessionStore.profile.primaryStoreKey)
          } else {
            // set primary store Reference as default store
            if (this.profile.primaryStoreReference.storeKey) {
              console.log("SET FILIALE FROM PROFILE", this.profile.primaryStoreReference)
              locationStore.isOnlineStore && locationStore.setActiveStoreId(this.profile.primaryStoreReference.storeKey)
            }

          }

          // set primary store Reference as default store
          if (!this.isLDAP && this.profile.primaryVehicle) {
            console.log("SET MANUFACTURER FROM PROFILE", this.profile.primaryVehicle)
            locationStore.setActiveManufacturerKey(this.profile.primaryVehicle.manufacturer)
          }

          Sentry.setUser({
            email: this.profile.email, username: `${this.profile.userName}`, id: this.profile.customerId
          })

          this.busy = true
          locationStore.setBusy(true)
          let loadingTime = 500 + (profile.vehicles.length * 400)
          locationStore.setLoading(true, loadingTime)

          let promises = profile.vehicles.map((vehicle, n) => {
            if (vehicle.vehicleId === this.profile.settings.defaultVehicleId) {
              vehicle.isDefault = true
              if (!locationStore.activeVehicleReferenceId) {
                locationStore.setActiveVehicleReferenceId(vehicle.vehicleReferenceId, vehicle)
              }
            }

            this.vehicleById.set(vehicle.vehicleId, vehicle)
            if (n < 5) return criteriaStore
              .findCar(vehicle, { useCache: true }, vehicle)
              .then(car => {
                loadingTime = loadingTime - 400
                locationStore.setLoading(true, loadingTime)
                //vehicle = car
              })
              .catch(err => {
                console.warn("VEHICLE NOT FOUND", vehicle, err)
              })
          })
          // no wait until vehicle data is loaded
          Promise.all(promises)

          // this._profilesLoaded = true
          externalCustomer.hydrate()
          console.info(`whoami::profile`, this.profile, profile)
          this.ready = true
          locationStore.setLoading(false)
          // RELOAD PROFILE ID
          locationStore.setActiveVehicleProfileId(locationStore.activeVehicleProfileId)
          this.busy = false
          locationStore.setBusy(false)

          try {
            const offersRes = await fetch(`${process.env.REACT_APP_PIMCORE_URL}/api/offer/customer/${this.profile.customerId}?apiKey=${API_KEY}`)
            const offers = await offersRes.json()
            this.customerOffers = new Set(offers)
            this.offersByLicensePlate = {}
            this.offersByLicensePlateUnfiltered = {}
            offers.forEach(offer => {
              const isViewable = CUSTOMER_VIEWABLE_STATUS.includes(offer.status)
              const bucket = this.offersByLicensePlate[offer.licensePlate] || []
              const bucketUnfiltered = this.offersByLicensePlateUnfiltered[offer.licensePlate] || []

              isViewable && bucket.push(offer)
              bucketUnfiltered.push(offer)
              this.offersByLicensePlate[offer.licensePlate] = bucket
              this.offersByLicensePlateUnfiltered[offer.licensePlate] = bucketUnfiltered
              try {
                const offerVehicle = JSON.parse(offer.activeVehicleProfile)
                let vehicleProfile = this.profile.vehicles.find(vehicleProfile => vehicleProfile.licensePlate === offer.licensePlate)
                vehicleProfile = vehicleProfile || this.vehicleById.get(offerVehicle.vehicleId)
                if (vehicleProfile) {
                  setTimeout(() => {
                    vehicleProfile.updateOfferSummary()
                  }, 500)
                }
              } catch (err) {
                console.error("Could not parse offer vehicle", err)
              }
            })
          } catch (e) {
            console.log("Could not load customer offers", e)
            this.busy = false
            locationStore.setBusy(false)
          }
        }
        return profile
      })
      .catch(e => {
        console.error(e, "DESTROYING SESSION FROM ERROR")
        // this.destroySession()
        // this.loading = false
        // this.ready = true
        // this.isLoggedIn = false
        this.profile = false
        this.loading = false
        this.ready = true
        return false
      })
  }


  fetchInvoices(vehicleId) {
    return axios(`${config.URL_BASE}api/v1/customers/me/vehicles/${vehicleId}/history/invoices`, {
      method: "GET", credentials: "include", mode: "cors", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json; charset=utf-8", "IdentityUserId": this.identityUserId
      }
    }).then(res => {
      let invoices = res.data.map(invoiceData => new InvoiceModel(invoiceData))
      invoices = _.orderBy(invoices, "invoiceDate", ["asc"])
        .reverse()
      this.invoiceByVehicleProfile.set(vehicleId, invoices)
      return invoices
    }).catch(err => {
      return []
    })
  }

  fetchInvoicesTeam({
    vehicleId, vehicleIdentificationNumber, storeCustomerNumbers
  }) {
    const body = {
      vehicleIdentificationNumber, storeCustomerNumbers
    }
    return fetch(`${config.URL_BASE}api/v1/customers/invoices`, {
      method: "POST", body: JSON.stringify(body), headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        let invoices = res.map(invoiceData => new InvoiceModel(invoiceData))
        invoices = _.orderBy(invoices, "invoiceDate", ["asc"])
          .reverse()
        this.invoiceByVehicleProfile.set(vehicleId, invoices)
        return invoices
      }).catch(err => {
        return []
      })
  }

  setVehicle(vehicle) {
    this.vehicle = vehicle
  }

  putDetailsWithoutIdentityId = (details) => {
    return axios(`${config.URL_BASE}api/v1/customers/me/details`, {
      method: "PUT", credentials: "include", mode: "cors", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json; charset=utf-8"
      }, data: details
    })
  }

  putDetails = (details) => {
    return axios(`${config.URL_BASE}api/v1/customers/me/details`, {
      method: "PUT", credentials: "include", mode: "cors", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json; charset=utf-8", "IdentityUserId": this.originalUserProfile?.identityUserId || this.identityUserId
      }, data: details
    })
  }

  putClientSettings = (data) => {
    return axios(`${config.URL_BASE}api/v1/customers/me/details`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: {
        "Authorization": this.authorization, "Content-Type": "application/json; charset=utf-8", "IdentityUserId": this.identityUserId
      }, data
    }).then(res => {
      console.log("UPDATE SETTING", res)
      return res
    })
      .catch(err => {
        console.warn("Error setting settings 2")
        return data
      })
  }


  setItem(key, value, retryCount = 0) {
    try {
      store.set(key, value)
    } catch (error) {
      console.error("Error setting item:", error)
      if (retryCount < 5) {
        this.evictOptionals()
        this.setItem(key, value, retryCount + 1)
      }
    }
  }

  getItem(key) {
    return store.get(key)
  }

  evictOptionals() {
    Object.keys(localStorage)
      .forEach(key => {
        if (key.startsWith("local:")) {
          store.remove(key)
        }
        if (key.startsWith("http")) {
          store.remove(key)
        }
      })
  }

  removeItem(key) {
    return store.remove(key)
  }

  registerExternal({
    username, password, confirmPassword, referrer = "./"
  }) {
    store.set("activation", {
      email: username, password, referrer
    })
    return axios.post(`${config.URL_BASE}api/v1/customers/store`, {
      email: username, password, confirmPassword
    })
  }


  async fetchOpenAppointments() {
    const customerNumber = this.profile.customerNumber
    // https://ncggtwebserver.versatio.de/api/v2/appointments?storeKey=NAGEL_SOHN_BORGHOLZHAUSEN&customerNumber=[NUMMER]
    // https://ncggtwebserver.versatio.de/api/v2/appointments?storeKey=NAGEL_SOHN_BORGHOLZHAUSEN&customerNumber=296418
    if (!customerNumber) return []
    try {
      const openAppointments = await shop.fetch(`v2/appointments?customerNumber=${customerNumber}`)
        .then(res => res.json())
      this.openAppointments = openAppointments || []

    } catch (err) {
      this.openAppointments = []
    }


    // console.log({openAppointments:this.openAppointments})
    return this.openAppointments
  }

  ping() {
    return axios(`${config.URL_BASE}api/v1/customers/me/ping`, {
      method: "GET", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }
    }).then(ping => {
      console.log({ ping })
      if (ping.status !== 200) {
        this.destroySession()
        document.location.reload()
        return
      }
      this._timeout = setTimeout(() => {
        this.ping()
      }, 320 * 1000)
    })
      .catch(err => {
        console.error("PING ERROR", err)
        this.destroySession()
        document.location.reload()
        return
      })
  }

  registerSilent({
    email, primaryCustomerNumber
  }) {
    // console.log( "REGISTER SILENT", { email, primaryCustomerNumber } )
    return axios(`${config.URL_BASE}api/v1/customers/store/silent`, {
      method: "POST", headers: {
        "Authorization": this.authorization, "Content-Type": "application/json"
      }, data: {
        primaryCustomerNumber, email: email ? `${email}`.trim() : null
      }
    })
  }


  async setDSEAnonymously({
    email = null,
    firstName = null,
    lastName = null,
    storeKey = null,
    invoiceEmail = null,
    emailGranted = false,
    phoneGranted = false,
    phoneNumber = false,
    textGranted = false,
    letterGranted = false
  }) {
    const body = {
      email, invoiceEmail, firstName, lastName, phoneNumber, storeKey, emailGranted, phoneGranted, textGranted, letterGranted,
    }

    // CLEAN NULL VALUES
    Object.keys(body).forEach((k) => body[k] == null && delete body[k]);

    return shop.fetch("v1/customers/dse/request/anonymous", {
      body: JSON.stringify(body), method: "POST"
    })
  }

  register({ registration } = { registration: this.registration }) {
    // --> console.log( {registration} )
    const userdata = {
      "email": registration.user.email,
      "password": registration.user.password,
      "confirmPassword": registration.user.confirmPassword,
      "firstname": registration.user.firstName,
      "lastname": registration.user.lastName,
      "companyName": registration.user.companyName,
      "phoneNumber": registration.user.phoneNumber,
      "title": registration.user.title,
      "passwordConfirm": registration.user.confirmPassword,
      "careID": null,
      "birthday": null,
      "mailDSE": true,
      "agb": registration.user.acceptedTerms,
      "agbConfirmed": registration.user.acceptedTerms,
      "acceptedTerms": registration.user.acceptedTerms,
      "newsletterConfirmed": registration.user.newsletterConfirmed, // "vehicles":        [
      //   {
      //     "manufacturer":                registration.car.manufacturer,
      //     "model":                       registration.car.model,
      //     "licensenumber":               registration.car.licensenumber,
      //     "vehicleIdentificationNumber": null
      //   }
      // ],
      "addresses": [{
        "type": "DELIVERY",
        "title": registration.user.title,
        "companyName": registration.user.companyName,
        "firstname": registration.user.firstName,
        "lastname": registration.user.lastName,
        "street": registration.user.street,
        "housenumber": null,
        "city": registration.user.city,
        "zipCode": registration.user.plz,
        "country": "Deutschland",
        "countryCode": "de"
      }]
    }
    return axios.post(`${config.URL_BASE}api/v1/customers`, userdata)
  }

  loginWithPassword = async (username, password, rememberMe = false, isLdapUser) => {
    username = `${username}`.trim()
    this.loading = true

    this.logout({ reload: false })

    const body = new FormData()
    body.append("username", isLdapUser ? `ldap_${username}` : username)
    body.append("password", password)
    body.append("rememberMe", rememberMe)

    body.append("client_id", "ncgdirect")
    body.append("grant_type", "password")
    body.append("scope", "openid order user")


    // fetch( TOKEN_URL "http://ncggttestapplication.versatio.de:5002/connect/token" , {
    const res = await axios.post(TOKEN_URL, body)
      .then(this.setTokenFromResponse)
      .then(() => this.whoami())
      .catch(this.handleError)

    this.fetchAdditionalData()
    return res
  }

  handleError = (error) => {
    //toast.error( `${err}` )
    this.loading = false
    return { error }
  }

  setTokenFromResponse = (response) => {
    this.access_token = response.data.access_token
    this.authorization = `Bearer ${this.access_token}`
    store.set("token", this.access_token)
    return response.data
  }

  setLoginData = evt => this.loginData[evt.target.name] = evt.target.value
}
